import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import UserService from "../../services/UserService";
import { useDispatch } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action";
import "../css/userLibrary.css";
import Loader from "react-js-loader";
import { Link, useNavigate } from "react-router-dom";
import { tabItem } from "../Agreement_Data/tabItem";
const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;
const TailUserLibrary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [documentName, setDocuemntName] = useState("")
  const [mode, setMode] = useState("")
  const [outputDetails, setOutputDetails] = useState([]);
  const [selectedoutputDetails, setselectedOutputDetails] = useState({});
  const [masterOutputArray, setMasterOutputArray] = useState([]);
  const [agreementContent, setAgreementContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [searchValueControl, setSearchValueControl] = useState(false);
  const [page, setPage] = useState(0);
  const [endLoader, setEndLoader] = useState(false);
  const [pageNo, setPageNo] = useState("");
  const [shouldCall, setShouldCall] = useState(true);
  const [scriptListVisible, setScriptListVisible] = useState(false)

  // for tags
  const [tagLoaderId, setTagLoaderId] = useState("");
  const [editCheckOfTag, setEditCheckOfTag] = useState(false);
  const [tagLoader, setTagLoader] = useState(false);
  const [beforeEditContent, setBeforeEditContent] = useState("");
  // for tags

  // const [renameString, setRenameString] = useState("New File");

  useEffect(() => {
    getAllUserOutput();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const clickPreview = () => {
    setTimeout(() => {
      document.querySelector(".tempPrev") &&
        document.querySelector(".tempPrev").click();
    }, 200)
  }

  function handleapioutput(x) {
    if (typeof x === "object") {
      if (Array.isArray(x)) {
        let conmessage = "";
        x.map((it) => {
          if (typeof it === "string") {
            conmessage = conmessage.concat(it + "\n");
          } else if (it.choices) {
            conmessage = conmessage.concat(
              it.choices[0].message.content + "\n"
            );
          } else {
            conmessage = conmessage.concat(it.content_text + "\n");
          }
        }); //console.log(conmessage);
        return conmessage;
      } else {
        return x.choices[0].message.content + "\n";
      }
    }
  }

  const scrollContainerRef = useRef(null);

  function handleScroll() {
    const scrollContainer = scrollContainerRef.current;
    const scrollPercentage =
      (scrollContainer.scrollTop /
        (scrollContainer.scrollHeight - scrollContainer.clientHeight)) *
      100;

    if (scrollPercentage >= 80) {
      // When the scrollbar reaches 80% of the content height
      // Call your function here
      if (shouldCall && !endLoader) getAllUserOutput("add", "No refresh");
    }
  }

  const getAllUserOutput = async (whatToDO, value) => {
    let temp = 1;
    if (whatToDO === "add") temp = page + 1;
    setPage(temp);
    if (temp === pageNo) setShouldCall(false);

    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("keycloak_user_id", UserService.getUserId());
        sendData.append("page", temp);
        sendData.append("page_size", 10);
        sendData.append("force_refresh", false);
        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/get_all_user_outputs_paged`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
          data: sendData,
        };
        if (!value) setLoader(true);
        if (whatToDO === "add") setEndLoader(true);

        const getResponse = await axios(config);
        if (getResponse.status === 200) {
          setPageNo(Math.ceil(getResponse.data.total_count / 10));
          // const reverse = getResponse.data.reverse()
          let temp = outputDetails;

          if (whatToDO === "add") {
            temp = temp.concat(getResponse.data.documents);

            setOutputDetails(temp);
            setMasterOutputArray(temp);
            setselectedOutputDetails(getResponse.data.documents[0])
          } else {
            setOutputDetails(getResponse.data.documents);
            setMasterOutputArray(getResponse.data.documents);
            setselectedOutputDetails(getResponse.data.documents[0])
          }
          clickPreview()
          // dispatch(getNotification({
          //     message: `Congratulation. You have  ${download} ${download === 1 ? "credit" : "credits"}`,
          //     type: "info"
          // }))
        }
      } catch (err) {
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
            });
          }, 3000);
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time",
              type: "default",
            })
          );
        }
      }
      setLoader(false);
      setEndLoader(false);
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
        });
      }, 3000);
    }
  };
  const getPreview = (e) => {
    setselectedOutputDetails(outputDetails[e]);
    const data = handleapioutput(outputDetails[e].api_output);
    setAgreementContent(data);
    setDocuemntName(outputDetails[e].api_input.tipsytom_input.agreement_type)
    setMode(outputDetails[e].api_input.tipsytom_input.api_type_alias)
  };

  const preventFromCopyPaste = () => {
    const edit = document.getElementById("edit");
    if (edit) {
      edit.addEventListener("copy", preventDefault);
      edit.addEventListener("cut", preventDefault);

      function preventDefault(e) {
        e.preventDefault();
      }
    }
  };
  const capitalizeAfterSpace = (text) => {
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const download = async (fileName, content) => {
    if (UserService.isLoggedIn()) {
      const text = content;
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("keycloak_user_id", UserService.getUserId());
        sendData.append("input_text", text);
        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/export_as_docx`,
          responseType: "blob",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
          data: sendData,
        };
        // setLoaderDownload(true);
        const getResponse = await axios(config);
        if (getResponse.status === 200) {
          const getCurrentDate = () => {
            const currentDate = new Date();
            const year = String(currentDate.getFullYear()).slice(-4);
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            return `${year}-${day}-${month}`;
          };
          const blob = new Blob([getResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${fileName}_${UserService.getUsername()}_${getCurrentDate()}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // dispatch(
          //   getNotification({
          //     message: "Downloaded successfully",
          //     type: "success",
          //   })
          // );
        }
      } catch (err) {
        console.log("error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
            });
          }, 3000);
        }
        console.log("Error", err);
        dispatch(
          getNotification({
            message:
              "You have encountered an error. Please try after some time.",
            type: "default",
          })
        );
      }
      // setLoaderDownload(false);
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
        });
      }, 3000);
    }
    // setTimeout(() => preventFromCopyPaste(), 10);
  };
  const downloadanticipatory = async (fileName, content) => {
    if (UserService.isLoggedIn()) {
      try {
        const obj = {
          keycloak_user_id: UserService.getUserId(),
          completions_dict: content.api_output,
          court_place: content.api_input.tipsytom_input.court_place,
          petitioner_number: content.api_input.tipsytom_input.petitioner_number,
          petitioner_name: content.api_input.tipsytom_input.petitioner_name,
          complainant_name: content.api_input.tipsytom_input.complainant_name,
          advocate_name: content.api_input.tipsytom_input.advocate_name,
          advocate_residence_city:
            content.api_input.tipsytom_input.advocate_residence_city,
        };
        var obj2 = JSON.stringify(obj);
        //console.log(obj);
        const apiUrl = `${REACT_APP_CREATE_AGREEMENT_API}/export_anticipatory_bail_docx`;
        const getResponse = await axios.post(apiUrl, obj2, {
          responseType: "blob",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
        });

        //setLoaderDownload(true);
        //const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.status === 200) {
          const getCurrentDate = () => {
            const currentDate = new Date();
            const year = String(currentDate.getFullYear()).slice(-4);
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            return `${year}-${day}-${month}`;
          };
          const blob = new Blob([getResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${fileName}_${UserService.getUsername()}_${getCurrentDate()}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // dispatch(
          //   getNotification({
          //     message: "Downloaded successfully",
          //     type: "success",
          //   })
          // );
        }
      } catch (err) {
        //setLoaderDownload(false);
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/anticipatory-bail-application`,
            });
          }, 3000);
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time.",
              type: "default",
            })
          );
        }
      }
      //setLoaderDownload(false);
      setTimeout(() => preventFromCopyPaste(), 10);
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/app/anticipatory-bail-application`,
        });
      }, 3000);
    }
  };

  const downloadbail = async (fileName, content) => {
    if (UserService.isLoggedIn()) {
      try {
        const obj = {
          keycloak_user_id: UserService.getUserId(),
          completions_dict: content.api_output,
          court_place: content.api_input.tipsytom_input.court_place,
          petitioner_number: content.api_input.tipsytom_input.petitioner_number,
          petitioner_name: content.api_input.tipsytom_input.petitioner_name,
          complainant_name: content.api_input.tipsytom_input.complainant_name,
          advocate_name: content.api_input.tipsytom_input.advocate_name,
          advocate_residence_city:
            content.api_input.tipsytom_input.advocate_residence_city,
        };
        var obj2 = JSON.stringify(obj);

        const apiUrl = `${REACT_APP_CREATE_AGREEMENT_API}/export_bail_docx`;
        const getResponse = await axios.post(apiUrl, obj2, {
          responseType: "blob",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
        });
        // const config = {
        //   method: "post",
        //   url: `${REACT_APP_CREATE_AGREEMENT_API}/export_anticipatory_bail_docx`,
        //   responseType: "blob",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     access_token: UserService.getToken(),
        //   },
        //   data: sendData,
        // };
        //setLoaderDownload(true);
        //const getResponse = await axios(config);

        if (getResponse.status === 200) {
          const getCurrentDate = () => {
            const currentDate = new Date();
            const year = String(currentDate.getFullYear()).slice(-4);
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            return `${year}-${day}-${month}`;
          };
          const blob = new Blob([getResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${fileName}_${UserService.getUsername()}_${getCurrentDate()}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // dispatch(
          //   getNotification({
          //     message: "Downloaded successfully",
          //     type: "success",
          //   })
          // );
        }
      } catch (err) {
        //setLoaderDownload(false);
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/bail-application`,
            });
          }, 3000);
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time.",
              type: "default",
            })
          );
        }
      }
      //setLoaderDownload(false);
      setTimeout(() => preventFromCopyPaste(), 10);
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/app/bail-application`,
        });
      }, 3000);
    }
  };
  const downloadexemptionfrompa = async (fileName, content) => {
    if (UserService.isLoggedIn()) {
      try {
        const obj = {
          keycloak_user_id: UserService.getUserId(),
          completions_dict: content.api_output,

        };
        var obj2 = JSON.stringify(obj);

        const apiUrl = `${REACT_APP_CREATE_AGREEMENT_API}/export_exemption_personal_appearance_docx`;
        const getResponse = await axios.post(apiUrl, obj2, {
          responseType: "blob",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
        });
        // const config = {
        //   method: "post",
        //   url: `${REACT_APP_CREATE_AGREEMENT_API}/export_anticipatory_bail_docx`,
        //   responseType: "blob",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     access_token: UserService.getToken(),
        //   },
        //   data: sendData,
        // };
        //setLoaderDownload(true);
        //const getResponse = await axios(config);

        if (getResponse.status === 200) {
          const getCurrentDate = () => {
            const currentDate = new Date();
            const year = String(currentDate.getFullYear()).slice(-4);
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            return `${year}-${day}-${month}`;
          };
          const blob = new Blob([getResponse.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `${fileName}_${UserService.getUsername()}_${getCurrentDate()}.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // dispatch(
          //   getNotification({
          //     message: "Downloaded successfully",
          //     type: "success",
          //   })
          // );
        }
      } catch (err) {
        //setLoaderDownload(false);
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
            });
          }, 3000);
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time.",
              type: "default",
            })
          );
        }
      }
      //setLoaderDownload(false);
      setTimeout(() => preventFromCopyPaste(), 10);
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
        });
      }, 3000);
    }
  };
  const updateFileName = async (ele) => {
    const fileNameSpan = document.getElementById(`file-name-${ele.id}`);
    const customInput = document.getElementById(`custom-input-${ele.id}`);
    const customCheck = document.getElementById(`custom-check-${ele.id}`);
    const customEdit = document.getElementById(`custom-edit-${ele.id}`);
    const customSeparateLoader = document.getElementById(
      `custom-loader-${ele.id}`
    );
    const renameString = customInput.value;

    if (UserService.isLoggedIn()) {
      try {
        customCheck.classList.add("d-none");
        customSeparateLoader.classList.remove("d-none");
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("keycloak_user_id", UserService.getUserId());
        sendData.append("new_file_name", renameString);
        sendData.append("id", ele.id);
        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/update_file_name`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        // console.log(getResponse);
        if (getResponse.status === 200) {
          // console.log(getResponse);
          fileNameSpan.innerText =
            renameString.length && renameString.length > 11
              ? `${renameString.slice(0, 11)}...`
              : renameString;
          getAllUserOutput("", "No Refresh");
          // dispatch(
          //   getNotification({
          //     message: "File name updated",
          //     type: "success",
          //   })
          // );
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time",
              type: "default",
            })
          );
        }
      } catch (err) {
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
            });
          }, 3000);
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time",
              type: "default",
            })
          );
        }
      }
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
    }
    fileNameSpan.classList.remove("d-none");
    customSeparateLoader.classList.add("d-none");
    customEdit.classList.remove("d-none");
    customInput.classList.add("d-none");
  };

  const updateFileTags = async (parentElement, idValue) => {
    if (UserService.isLoggedIn()) {
      try {
        const tags = parentElement.innerText
          .replaceAll(",", ";")
          .replace(/;$/, "");

        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("keycloak_user_id", UserService.getUserId());
        sendData.append("file_tag_to_add", tags);
        sendData.append("id", idValue);
        sendData.append("append_mode", false);

        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/update_file_tag`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            access_token: UserService.getToken(),
          },
          data: sendData,
        };
        setTagLoader(true);
        const getResponse = await axios(config);
        // console.log(getResponse);
        if (getResponse.status === 200) {
          getAllUserOutput("", "No refresh");
          // dispatch(
          //   getNotification({
          //     message: "File tag updated",
          //     type: "success",
          //   })
          // );
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time",
              type: "default",
            })
          );
        }
      } catch (err) {
        console.log("Error", err);
        parentElement.innerText = beforeEditContent;
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/app/library`,
            });
          }, 3000);
        } else {
          dispatch(
            getNotification({
              message:
                "You have encountered an error. Please try after some time",
              type: "default",
            })
          );
        }
      }
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
    }
    setEditCheckOfTag(false);
    setTagLoader(false);
  };

  window.addEventListener("scroll", function () {
    const scrollUp = document.querySelector(".up");
    const scrollDown = document.querySelector(".down");
    if (window.scrollY === 0) {
      if (scrollUp) scrollUp.classList.add("d-none");
    } else if (
      Math.floor(window.scrollY + window.innerHeight) ===
      document.documentElement.scrollHeight - 1 ||
      window.scrollY + window.innerHeight ===
      document.documentElement.scrollHeight ||
      window.scrollY + window.innerHeight >=
      document.documentElement.scrollHeight
    ) {
      if (scrollDown) scrollDown.classList.add("d-none");
    } else {
      if (scrollDown) scrollDown.classList.remove("d-none");
      if (scrollUp) scrollUp.classList.remove("d-none");
    }
  });
  const goDown = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };
  const goUp = () => {
    document
      .getElementById("library-header")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  const searchResult = (event) => {
    event.preventDefault();
    const searchString = document.querySelector(".search-input");
    let filteredResult = [];
    if (searchString.value && searchString.placeholder === "Enter File Name") {
      filteredResult = outputDetails.filter((item) =>
        item.file_name.toLowerCase().includes(searchString.value.toLowerCase())
      );
      setSearchValueControl(true);
    } else if (
      searchString.value &&
      searchString.placeholder === "Enter Date (yyyy-mm-dd)"
    ) {
      filteredResult = outputDetails.filter((item) =>
        item.insert_timestamp
          .toLowerCase()
          .includes(searchString.value.toLowerCase())
      );
      setSearchValueControl(true);
    } else if (searchString.value && searchString.placeholder === "Enter Tag") {
      filteredResult = outputDetails.filter((item) =>
        item.file_tags.toLowerCase().includes(searchString.value.toLowerCase())
      );
      setSearchValueControl(true);
    }
    else if (searchString.value && searchString.placeholder === "Enter Scripter") {
      filteredResult = outputDetails.filter((item) =>
        item.api_input.tipsytom_input.agreement_type.toLowerCase().includes(searchString.value.toLowerCase())
      );
      setSearchValueControl(true);
    }

    filteredResult.length === 0
      ? setOutputDetails(masterOutputArray) && setSearchValueControl(false)
      : setOutputDetails(filteredResult);

    dispatch(
      getNotification({
        message: `${filteredResult.length} result${filteredResult.length >= 2 ? "s" : ""
          } found`,
        type: "default",
      })
    );
    setTimeout(() => {
      clickPreview()
    }, 200);
  };
  const convertUTCToLocalTime = (utcTime) => {
    const date = new Date(utcTime);
    const localTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return localTime;
  };
  const converFormat = (value) => {
    const inputDate = value;
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  const pushPath = {
    "legal notice or communication": "legal-notice",
    "power editor": "power-editor",
    "non disclosure agreement": "non-disclosure-agreement",
    "employment agreement": "employment-agreement",
    "service agreement": "service-agreement",
    "supply agreement": "supply-agreement",
    "intellectual property licensing agreement": "ip-licensing-agreement",
    "intellectual property assignment agreement": "ip-assignment-agreement",
    "lease agreement": "lease-agreement",
    "bail application": "bail-application",
    "anticipatory bail application": "anticipatory-bail-application",
    "exemption application from pa": "exemption-application-from-pa",
    "Exemption Application (Personal Appearance)": "exemption-application-from-pa",
  };

  const getMode = () => {
    if (capitalizeAfterSpace(documentName) === "Exemption Application (Personal Appearance)" || capitalizeAfterSpace(documentName) === "Exemption Application (Personal Appearance)" || capitalizeAfterSpace(documentName) === "Exemption Application From Pa" || capitalizeAfterSpace(documentName) === "Bail Application" || capitalizeAfterSpace(documentName) === "Anticipatory Bail Application") {
      return ""
    }
    else if (mode) {
      return mode === "Basic" ? "Falcon" : "Owl"
    }
    else if (!mode) {
      return outputDetails.length != 0 &&
        outputDetails[0].api_input.tipsytom_input.api_type_alias === "Basic" ? "Falcon" : "Owl"
    }
    else if (!documentName) {
      return ""
    }
    else return ""
  }


  if (loader) {
    return (
      <div style={{ margin: "30vh 0 20vh 0" }} className="mx-auto">
        <Loader
          type="bubble-spin"
          bgColor={"#000000"}
          title={"Loading Document Library..."}
          color={"#000000"}
          size={100}
        />
      </div>
    );
  } else if (outputDetails.length <= 0) {
    return (
      <div className="empty-library-page">
        <div className="empty-library-content">
          <svg
            style={{ width: "100px", marginLeft: "9rem" }}
            className="empty-library-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm1-14h-2v7h2zm0 9h-2v2h2z" />
          </svg>
          <h2 className="empty-library-heading">No documents found.</h2>
          <p className="empty-library-text">
            There are no documents available in the library.
          </p>
          <Link to="/app" className="btn btn-primary">
            Generate your first draft now!
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <section>
        <div className="d-flex container-fluid py-2">
          <div className="row mt-5">
            <div className="col-sm">
              {/* <div className="up-down">
          <button onClick={() => goUp()} title='Go Up!' className='btn btn-outline-info up d-none'><i className="fas fa-angle-up"></i></button>
          <br />
          <br />
          <button onClick={() => goDown()} title='Go Down!' className='btn btn-outline-info down'><i className="fas fa-angle-down"></i></button>
        </div> */}
              <div
                ref={scrollContainerRef}
                onScroll={handleScroll}
                style={{ height: "1250px" }}
                className="overflow-y-auto overflow-x-hidden sm:px-0 shadow sm:rounded-md"
              >
                <div
                  className="container-fluid"
                  style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)" }}>
                  <form className="form-inlin">
                    <div className="row" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }}>
                      <div className="col-sm-3 py-2">
                        <p className="text-primary text-center mt-2" style={{ fontSize: "1.2rem", fontWeight: "0px" }}>My Drafts</p>
                      </div>
                      <div className="col-sm-9 mt-3 flex">
                        <div className="input-group-prepend">
                          <button
                            className="btn btn-outline-secondary dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ height: "37px" }}>
                            Search By
                          </button>
                          <div className="dropdown-menu cursor-pointer">
                            <span
                              onClick={() => {
                                setScriptListVisible(false)
                                document.querySelector(".search-input").placeholder =
                                  "Enter File Name";
                              }}
                              className="dropdown-item"
                            >
                              Name
                            </span>
                            <span
                              onClick={() => {
                                setScriptListVisible(false)
                                document.querySelector(".search-input").placeholder =
                                  "Enter Date (yyyy-mm-dd)";
                              }}
                              className="dropdown-item"
                            >
                              Date
                            </span>
                            <span
                              onClick={() => {
                                setScriptListVisible(false)
                                document.querySelector(".search-input").placeholder =
                                  "Enter Tag";
                              }}
                              className="dropdown-item"
                            >
                              Tag
                            </span>
                            <span
                              onClick={() => {
                                setScriptListVisible(true)
                                document.querySelector(".search-input").placeholder =
                                  "Enter Scripter";
                              }}
                              className="dropdown-item"
                            >
                              Scripter
                            </span>
                          </div>
                        </div>

                        <input
                          type="text"
                          list="suggestions"
                          className="form-control mx-1.5 search-input"
                          placeholder="Enter File Name"
                        />
                        {
                          scriptListVisible &&
                          <datalist id="suggestions">
                            {
                              tabItem && tabItem.map((ele, ind) => (
                                <option key={ind} value={ele.name} />
                              ))
                            }
                            {/* Add more suggestions as needed */}
                          </datalist>
                        }
                        <button
                          spiketip-title="Search File"
                          spiketip-pos="bottom"
                          onClick={(e) => searchResult(e)}
                          className="btn btn-outline-primary my-2 my-sm-0"
                          type="submit"
                          style={{ height: "37px" }}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setSearchValueControl(false);
                            setOutputDetails(masterOutputArray);
                          }}
                          spiketip-title="Reset Filter Result"
                          spiketip-pos="bottom"
                          className="btn btn-outline-primary my-2 my-sm-0 mx-1.5"
                          style={{ height: "37px" }}
                        >
                          <i className="fas fa-redo"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <h3 id="library-header" className="text-2xl text-gray-700 font-bold mb-6 ml-3">
            User Library
          </h3> */}
                <div className="mt-2 p-2 px-4">
                  {outputDetails &&
                    outputDetails.map((ele, ind) => (
                      <div key={ind} className="row border-gray-300 shadow-sm rounded sm:rounded-lg m-2 my-2">
                        <div className="col-sm-4 p-1 bg-[#F9FAFF]">
                          {
                            !(capitalizeAfterSpace(documentName) === "Exemption Application (Personal Appearance)" || capitalizeAfterSpace(ele.api_input.tipsytom_input.agreement_type) === "Exemption Application From Pa" || capitalizeAfterSpace(ele.api_input.tipsytom_input.agreement_type) === "Bail Application" || capitalizeAfterSpace(ele.api_input.tipsytom_input.agreement_type) === "Anticipatory Bail Application")
                            &&
                            <span
                              className={
                                ele.api_input.tipsytom_input.api_type_alias ===
                                  "Pro"
                                  ? "bg-[#E4F1F7] text-info px-2 py-1 tracking-widest text-sm fw-bold absolute left-0 top-0 rounded-br"
                                  : "d-none"
                              }
                            >
                              OWL
                            </span>}
                          {
                            !(capitalizeAfterSpace(documentName) === "Exemption Application (Personal Appearance)" || capitalizeAfterSpace(ele.api_input.tipsytom_input.agreement_type) === "Exemption Application From Pa" || capitalizeAfterSpace(ele.api_input.tipsytom_input.agreement_type) === "Bail Application" || capitalizeAfterSpace(ele.api_input.tipsytom_input.agreement_type) === "Anticipatory Bail Application")
                            &&
                            <span
                              className={
                                ele.api_input.tipsytom_input.api_type_alias ===
                                  "Pro"
                                  ? "d-none"
                                  : "bg-[#EEE7F3] text-[#9F5F9D] px-2 py-1 tracking-widest text-sm fw-bold absolute left-0 top-0 rounded-br"
                              }
                            >
                              FALCON
                            </span>}
                          <div className="mx-auto px-3 flex flex-col items-center justify-center h-full">
                            <p
                              spiketip-title={`${ele.file_name}`}
                              spiketip-pos="bottom"
                              className="font-medium text-lg darkColor m-0"
                            >
                              <span
                                onClick={() => {
                                  document
                                    .getElementById(`file-name-${ele.id}`)
                                    .classList.add("d-none");
                                  document
                                    .getElementById(`custom-edit-${ele.id}`)
                                    .classList.add("d-none");
                                  document
                                    .getElementById(`custom-input-${ele.id}`)
                                    .classList.remove("d-none");
                                  document
                                    .getElementById(`custom-check-${ele.id}`)
                                    .classList.remove("d-none");
                                }}
                                id={`file-name-${ele.id}`}
                              >
                                {ele.file_name && ele.file_name.length > 11
                                  ? `${ele.file_name.slice(0, 11)}...`
                                  : `${ele.file_name}`}{" "}
                              </span>
                              <input
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    updateFileName(ele);
                                  }
                                }}
                                id={`custom-input-${ele.id}`}
                                defaultValue={
                                  searchValueControl ? "" : ele.file_name
                                }
                                style={{
                                  width: "120px",
                                  height: "25px",
                                  border: "2px solid grey",
                                  textAlign: "left",
                                }}
                                className="px-1 rounded btn d-none"
                                type="text"
                              />
                              <i
                                id={`custom-loader-${ele.id}`}
                                className="custom-loader mx-2 d-none"
                              ></i>
                              <i
                                onClick={() => {
                                  // console.log(document.getElementById(`file-name-${ele.id}`))
                                  document
                                    .getElementById(`file-name-${ele.id}`)
                                    .classList.add("d-none");
                                  document
                                    .getElementById(`custom-edit-${ele.id}`)
                                    .classList.add("d-none");
                                  document
                                    .getElementById(`custom-input-${ele.id}`)
                                    .classList.remove("d-none");
                                  document
                                    .getElementById(`custom-check-${ele.id}`)
                                    .classList.remove("d-none");
                                }}
                                id={`custom-edit-${ele.id}`}
                                className="fas fa-edit mx-2 cursor-pointer"
                              ></i>
                              <i
                                onClick={() => {
                                  updateFileName(ele);
                                }}
                                id={`custom-check-${ele.id}`}
                                className="fas fa-check mx-2 cursor-pointer d-none"
                              ></i>
                            </p>
                            <p className="font-medium darkColor text-sm mt-1">
                              <i className="fas fa-calendar-alt"></i>{" "}
                              {converFormat(ele.insert_timestamp.slice(0, 10))}{" "}
                              <i className="fas fa-clock"></i>{" "}
                              {convertUTCToLocalTime(ele.insert_timestamp)}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-8 p-1 bg-white">
                          <div className="row">
                            <div className="col-sm-8">
                              <div className="py-1 px-1 mt-2 fw-bold text-md text-primary">
                                {capitalizeAfterSpace(
                                  ele.api_input.tipsytom_input.agreement_type
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 flex">
                              <button
                                spiketip-title="Preview"
                                spiketip-pos="bottom"
                                onClick={(e) => {
                                  preventFromCopyPaste();
                                  document
                                    .getElementById("catchScroll")
                                    .scrollIntoView({ behavior: "smooth", block: "start", });
                                  setFileName(ele.file_name);
                                  // const tempNode =
                                  //   document.querySelectorAll(".tempPrev");
                                  // tempNode.forEach((e, i) => {
                                  //   e.classList.remove("btn-secondary");
                                  // });

                                  // e.target.classList.add("btn-secondary");
                                  getPreview(ind);
                                }}
                                className="btn btn-light mx-1 tempPrev p-1"
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                spiketip-title="Download"
                                spiketip-pos="bottom"
                                onClick={() => {
                                  if (ele.api_input.tipsytom_input.agreement_type === "anticipatory bail application") downloadanticipatory(ele.file_name, ele)
                                  else if (ele.api_input.tipsytom_input.agreement_type === "bail application") downloadbail(ele.file_name, ele)
                                  else if (
                                    ele.api_input.tipsytom_input.agreement_type ===
                                    "exemption application from pa"
                                    || ele.api_input.tipsytom_input.agreement_type ===
                                    "Exemption Application (Personal Appearance)"
                                  ) {
                                    downloadexemptionfrompa(ele.file_name, ele);
                                  }
                                  else download(ele.file_name, handleapioutput(ele.api_output))
                                }
                                }
                                className="btn btn-light mx-2 p-1"
                              >
                                <i className="fas fa-download"></i>
                              </button>
                              <button
                                spiketip-title="Continue Scripting"
                                spiketip-pos="bottom"
                                onClick={() => {
                                  const agreementType =
                                    ele.api_input.tipsytom_input.agreement_type;
                                  const mappedValue = pushPath[agreementType];
                                  if (mappedValue)
                                    navigate("/app/" + mappedValue, {
                                      state: { data: ele },
                                    });
                                  else
                                    navigate("/app/draft-agreement", {
                                      state: { data: ele },
                                    });
                                }}
                                className="btn btn-light mx- p-1"
                              >
                                <i className="fas fa-edit"></i>
                              </button>
                            </div>
                          </div>
                          <hr className="w-3/3 my-1 mx-0" />
                          <div className="">
                            <div
                              spiketip-title="Tags Added"
                              spiketip-pos="bottom"
                              className="row mx-auto mb-2">
                              <div
                                id={`tag-inner-text-${ele.id}`}
                                className={
                                  ele.file_tags !== "" &&
                                    ele.file_tags !== " " &&
                                    ele.file_tags !== "null"
                                    ? "col-10 alert alert-secondary p-1 mb-0 scrollable-span"
                                    : "d-none"
                                }
                              >
                                {ele.file_tags.replaceAll(";", ", ")}
                              </div>
                              <div
                                className={
                                  ele.file_tags !== "" &&
                                    ele.file_tags !== " " &&
                                    ele.file_tags !== "null"
                                    ? "col-2"
                                    : "d-none"
                                }
                              >
                                <button
                                  onClick={() => {
                                    const parentElement = document.getElementById(
                                      `tag-inner-text-${ele.id}`
                                    );
                                    setBeforeEditContent(parentElement.innerText);
                                    parentElement.setAttribute(
                                      "contentEditable",
                                      "true"
                                    );
                                    parentElement.focus();
                                    setTagLoaderId(ele.id);
                                    setEditCheckOfTag(true);
                                  }}
                                  spiketip-title="Edit Tags"
                                  spiketip-pos="bottom"
                                  style={{ height: "40px" }}
                                  className={
                                    editCheckOfTag && ele.id === tagLoaderId
                                      ? "d-none"
                                      : "btn btn-light"
                                  }
                                >
                                  <i className="fas fa-pen text-dark"></i>
                                </button>
                                <button
                                  onClick={() => {
                                    const parentElement = document.getElementById(
                                      `tag-inner-text-${ele.id}`
                                    );
                                    parentElement.setAttribute(
                                      "contentEditable",
                                      "false"
                                    );
                                    setTagLoader(true);
                                    updateFileTags(parentElement, ele.id);
                                    setTagLoaderId(ele.id);
                                  }}
                                  title="Update Tags"
                                  style={{ height: "40px" }}
                                  className={
                                    editCheckOfTag && ele.id === tagLoaderId
                                      ? "btn btn-secondary"
                                      : "d-none"
                                  }
                                >
                                  <i
                                    className={
                                      tagLoader && ele.id === tagLoaderId
                                        ? "custom-loader m-0"
                                        : "fas fa-check text-primary"
                                    }
                                  ></i>
                                </button>
                              </div>
                            </div>
                            <p className="text-gray-700">
                              {handleapioutput(ele.api_output) && handleapioutput(ele.api_output).slice(0, 170)}
                              ...
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className={endLoader ? "text-center" : "d-none"}>
                  <div className="spinner-border mt-4 text" role="status"></div>
                </div>
                {/* <hr id="end-line" className="text-dark" /> */}
              </div>
            </div>
            <div className="col-sm">
              <div>
                <div className="md:col-span-2 md:mt-0">
                  <div
                    style={{ height: "1250px" }}
                    className="sm:px-0 shadow sm:overflow-hidden sm:rounded-md"
                    id="catchScroll"
                  >
                    <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                      <div className='col-sm-8'>
                        <h5 className='ml-3 text-primary'>
                          {documentName
                            ? capitalizeAfterSpace(documentName)
                            : outputDetails.length != 0 &&
                            capitalizeAfterSpace(outputDetails[0].api_input.tipsytom_input.agreement_type)
                          }
                        </h5>
                        <p className='p-0 mx-3 my-0 text-primary'>
                          {getMode()}
                        </p>
                      </div>
                      <div className='col-sm-4 items-center float-end'>
                        <button
                          spiketip-title={"Download File"}
                          spiketip-pos="left"
                          id="download"
                          className="btn btn-primary mt-1 mx-1 float-end"
                          onClick={() => {
                            if (
                              selectedoutputDetails.api_input.tipsytom_input.agreement_type ===
                              "anticipatory bail application"
                            ) {
                              downloadanticipatory(fileName, selectedoutputDetails);
                            } else if (
                              selectedoutputDetails.api_input.tipsytom_input.agreement_type ===
                              "bail application"
                            ) {
                              downloadbail(fileName, selectedoutputDetails);
                            }
                            else if (
                              selectedoutputDetails.api_input.tipsytom_input.agreement_type ===
                              "exemption application from pa" || selectedoutputDetails.api_input.tipsytom_input.agreement_type === "Exemption Application (Personal Appearance)"
                            ) {
                              downloadexemptionfrompa(fileName, selectedoutputDetails);
                            } else {
                              download(fileName, agreementContent);
                            }
                          }}
                        >
                          <i className="fas fa-download"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      id="edit"
                      contentEditable={false}
                      style={{ height: "1110px", overflow: "auto" }}
                      className="mt-1 container mt-1 whitespace-pre-wrap px-3 block text-sm text-black"
                    >
                      {agreementContent
                        ? agreementContent
                        : outputDetails.length != 0 &&
                        handleapioutput(outputDetails[0].api_output)}
                      {/* <br /> */}
                    </div>
                    <div className="text-center">
                      <button
                        spiketip-title={"Download File"}
                        spiketip-pos="top"
                        className="btn btn-primary mt-4 block w-2/3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onClick={() => {
                          if (
                            selectedoutputDetails.api_input.tipsytom_input.agreement_type ===
                            "anticipatory bail application"
                          ) {
                            downloadanticipatory(fileName, selectedoutputDetails);
                          } else if (
                            selectedoutputDetails.api_input.tipsytom_input.agreement_type ===
                            "bail application"
                          ) {
                            downloadbail(fileName, selectedoutputDetails);
                          }
                          else if (
                            selectedoutputDetails.api_input.tipsytom_input.agreement_type ===
                            "exemption application from pa" || selectedoutputDetails.api_input.tipsytom_input.agreement_type === "Exemption Application (Personal Appearance)"
                          ) {
                            downloadexemptionfrompa(fileName, selectedoutputDetails);
                          } else {
                            download(fileName, agreementContent);
                          }
                        }}
                      >
                        Download <i className="fas fa-download mx-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
      </section >
    );
  }
};

export default TailUserLibrary;