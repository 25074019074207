import React from 'react'
import { Link } from 'react-router-dom';
import backgroundImageNav from "../../assets/backgroundImageNav.png"
import backgroundImageNav2 from "../../assets/backgroundImageNav2.png"
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'


const FooterLanding = () => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <footer className='relative btn-dark w-full' style={{ padding: "3vw 8vw 2vw 8vw", backgroundImage: `url(${backgroundImageNav})`, backgroundRepeat: "no-repeat", }}>
            <div>
                <div className='row'>
                    <div className="col-sm-3">
                            <h1 className="m-0 text-white text-[1.5rem]" >Draft A Bail Application</h1>
                    </div>
                    <div className="col-sm-9 pt-1">
                            <span className='sm:-ml-5 mr-5 text-white text-[0.7rem] sm:text-[1rem]' style={{ opacity: "0.5"}}>2024 @ Lipi Labs Private Limited. All Rights Reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterLanding