import React, { useEffect } from 'react'
import "../../components/css/landingpage.css"
import { useDispatch, useSelector } from 'react-redux'
import { getNavbar } from '../../Redux/Action/action'
import { redirect, useNavigate } from 'react-router'
import UserService from '../../services/UserService'
import FooterLanding from './FooterLanding'


import firstsectionbackground from "../../assets/firstsectionbackground.png"
import firstsectiongroup from "../../assets/firstsectiongroup.png"
import scriptListSS from "../../assets/scriptListSS.jpg"
import groupIcon from "../../assets/groupIcon.png"
import middleSS from "../../assets/middleSS.jpg"
import bussinessGroup from "../../assets/bussinessGroup.png"
import documentLibrary from "../../assets/draftReadyToGo2.png"
import forEveryone from "../../assets/forEveryone.png"
import scriptListBackground from "../../assets/scriptListBackground.png"
import scriptListBackgroundRight from "../../assets/scriptListBackgroundRight.png"
import { useLocation } from 'react-router'
import MyCarousel from './MyCarousel'
import vdo from '../../assets/Demo1.mp4'
import navbarBackgroundLeft from "../../assets/navbarBackgroundLeft.png"
import navbarBackgroundRight from "../../assets/navbarBackgroundRight.png"
import "../css/landingpage.css"


const { REACT_APP_REDIRECT_URI } = process.env;
const LandingTest = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        // Select the navbar element by its className name
        const navbar = document.querySelector(".navbar-custom");

        if (navbar) {
            // Hide the navbar
            navbar.style.display = "none";
        }

        // Cleanup function to restore the navbar visibility
        return () => {
            if (navbar) {
                navbar.style.display = "";
            }
        };
    }, []);

    return (
        <section>
            <div className='btn-dark py-3 text-center'>
                <img loading="lazy" className=".custom-position-explor" style={{ position: "absolute", top: "1vh", right: "-5vw", zIndex: "2", height: "8rem" }} src={navbarBackgroundLeft} alt="Navbar Background Left" />
                <img loading="lazy" style={{ position: "absolute", right: "45vw", zIndex: "2", height: "8rem" }} src={navbarBackgroundRight} alt="Navbar Background Right" />
                <h1 style={{ fontSize: "2rem" }} className=''>Draft a Bail Application</h1>
                <p className='text-lg'>Revolutionising the law with AI</p>
            </div>
            <div className='container-fluid responsive-padding-landing-page'>
                <div className="row">
                    <div className="col-lg-5 py-4 flex items-center justify-center flex-col">
                        <h1 className='welcome'>Draft a Bail Application in 10 minutes using AI</h1>
                        <p className='second-heading'>
                        Create high-quality and fully formatted Indian bail applications effortlessly.
                        </p>
                    </div>
                    <div className="col-lg-7">
                        <div className="flex justify-center items-center my-3">
                            <div className="relative bg-gray-800 rounded-lg shadow-lg w-[800px] h-[250px] sm:h-[500px] border border-gray-300">
                                {/* Top Bar */}
                                <div className="absolute top-0 left-0 w-full h-10 bg-gray-900 rounded-t-lg flex items-center px-4">
                                    <div className="w-3 h-3 bg-red-500 rounded-full mr-2"></div>
                                    <div className="w-3 h-3 bg-yellow-400 rounded-full mr-2"></div>
                                    <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                                </div>
                                {/* Video Section */}
                                <div className="absolute top-10 left-0 w-full h-[calc(100%-40px)] overflow-hidden rounded-b-lg">
                                    <video
                                        src={vdo}
                                        controls
                                        autoPlay
                                        loop
                                        muted
                                        className="w-full h-full object-cover"
                                    >
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br />
                <div className='my-5'>
                    <div className="row">
                        <div className="col-sm card-column">
                            <div className="card-details">
                                <div className="card-icons">
                                    <img width="48" height="48" src="https://img.icons8.com/parakeet-line/48/add-user-male.png" alt="add-user-male" />                                </div>
                                <h3>
                                    <button onClick={() => UserService.doSignUp({redirectUri: `${REACT_APP_REDIRECT_URI}/app`})}
                                        style={{ fontSize: "1.5rem" }}
                                        className='btn btn-outline-dark px-5'>
                                        Register
                                    </button>
                                </h3>
                                <p className='w-full'>Register now to start drafting a bail application using AI.</p>
                            </div>
                        </div>
                        <div className="col-auto flex justify-center items-center">
                            {/* Vertical Line */}
                            <div className="border-l-4 border-gray-400 h-full"></div>
                        </div>
                        <div className="col-sm card-column">
                            <div className="card-details">
                                <div className="card-icons">
                                    <img width="48" height="48" src="https://img.icons8.com/badges/48/enter-2.png" alt="enter-2" />                                                                    </div>
                                <h3>
                                    <button onClick={() => {
                                        if (UserService.isLoggedIn()) navigate("/app")
                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app" })
                                    }}
                                        style={{ fontSize: "1.5rem" }}
                                        className='btn btn-outline-dark px-5'>
                                        Sign In
                                    </button>
                                </h3>
                                <p>Proceed to your account to start drafting now. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default LandingTest