export const tabItem = [
    {
        name: "Use AI to Draft a Bail Application",
        description1:
            "AI powered automation for preparing a high quality and accurate bail application in less than 15 minutes.",
        buttonName: "Script an application now!",
        to: "/app/bail-application",
        paid: true,
        disabled: false,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true,
    },
    {
        name: "Use AI to Draft a Anticipatory Bail Application",
        description1:
            "AI powered automation for preparing a high quality and accurate anticipatory bail application in less than 15 minutes.",
        buttonName: "Script an application now! ",
        to: "/app/anticipatory-bail-application",
        paid: true,
        disabled: false,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true,
    },
];