import React, { useEffect, useState } from 'react'
import jQuery from 'jquery'
import UserService from "../../services/UserService"
import axios from 'axios'
import Loader from "react-js-loader"
import { ProgressBar } from "react-loader-spinner"
import { useDispatch, useSelector } from 'react-redux'
import { getNotification, getUserDetails } from '../../Redux/Action/action'
import { useNavigate } from 'react-router'
import "../css/home.css"
import "../css/subscription.css"
import FooterLanding from './FooterLanding'
import subscription1 from "../.././assets/subscription1.jpg"
import subscription2 from "../.././assets/subscription2.jpg"
import Popup from './Popup'
import VideoPopup from './VideoPopup'
import vdo from '../../assets/Demo1.mp4'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env
const Pricing = () => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [popup, setPopup] = useState(false)
    const [subscriptionData, setSubscriptionData] = useState([])
    const [loader, setLoader] = useState(false)
    const [warning, setWarning] = useState(false)
    const [currencyCode, setCurrencyCode] = useState("inr")
    const [monthly, setMonthly] = useState(true)
    const [currency, setCurrency] = useState(false)
    const [active, setActive] = useState("")
    const [validity, setValidity] = useState("")

    useEffect(() => {
        getSubscription()
        if (UserService.isLoggedIn()) getPlanDetails()
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    const getPlanDetails = async (value) => {
        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "access_token": UserService.getToken()
            };
            const getResponse = await axios.post(
                `${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
                null,
                { headers: headers }
            );
            if (getResponse.status === 200) {
                dispatch(getUserDetails(getResponse.data));
                if (Object.keys(getResponse.data.user_subscription_summary).length) {
                    const name = Object.entries(getResponse.data.user_subscription_summary)[0][1].plan.nickname
                    const valid = (Object.entries(getResponse.data.user_subscription_summary)[0][1].plan.amount_decimal) / 100
                    setActive(name)
                    setValidity(valid)
                }
                else {
                    setActive("Free")
                }
            }
        } catch (err) {
            console.log("Error", err);
            if (err.response.status === 400) {
                dispatch(getUserDetails(err.response.data.detail));

                setActive("Free")

                // dispatch(
                //   getNotification({
                //     message:
                //       "You have run out of credits!! Please subscribe and get more credits.",
                //     type: "info",
                //   })
                // );
            } else if (err.response && err.response.status === 401) {
                dispatch(
                    getNotification({
                        message: "Session expired! Please log in again",
                        type: "default",
                    })
                );
                setTimeout(() => {
                    UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/pricing` });
                }, 3000);
            } else {
                dispatch(
                    getNotification({
                        message: "You have encountered an error. Please try after some time",
                        type: "default",
                    })
                );
                navigate("/offline-error")
            }
        }
        // setLoader(false)
    };


    const getSubscription = async () => {
        // if (UserService.isLoggedIn()) {
        if (true) {
            const config = {
                method: "get",
                url: `${REACT_APP_CREATE_AGREEMENT_API}/get_subscriptions`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                },
            }
            try {
                setLoader(true)
                const getResponse = await axios(config)
                if (getResponse.status === 200) {
                    setLoader(false)
                    // const data = getResponse.data
                    // const elementToMove = data[5]

                    // // Find the index of the element to move
                    // const indexToMove = data.findIndex(item => item.subscription_id === elementToMove.subscription_id);

                    // // If the element exists in the array
                    // if (indexToMove !== -1) {
                    //     // Remove the element from the array
                    //     const removedElement = data.splice(indexToMove, 1)[0];

                    //     // Append the removed element back to the array
                    //     data.push(removedElement);
                    // }
                    setSubscriptionData([...getResponse.data])
                }
            }
            catch (err) {
                setLoader(false)
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Please log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/pricing` });
                    }, 3000);
                } else {
                    dispatch(
                        getNotification({
                            message: "Network issue. Please refresh the page or try again aftersometime.",
                            type: "default",
                        })
                    );
                    navigate("/offline-error")
                }
            }
        }
        setLoader(false)
    }


    const paymentPage = async (currCode, ID) => {
        setLoader(true)
        try {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "access_token": UserService.getToken()
            };
            const getResp = await axios.post(`${REACT_APP_CREATE_AGREEMENT_API}/create_checkout_session_for_subscription_id?keycloak_user_id=${UserService.getUserId()}&subscription_id=${ID}&currency_code=${currCode}&stripe_mode=${"subscription"}&stripe_email_id=${UserService.getEmail()}`,
                null,
                { headers: headers })
            if (getResp.status === 200) {
                const data = { session: getResp.data.session_id, subscriptionId: ID }
                // console.log(getResp.data);
                sessionStorage.setItem("data", JSON.stringify({ ...data }))
                // console.log(sessionStorage.getItem("data"));
                const payemntUrl = getResp.data.url
                setLoader(false)
                setWarning(true)
                setTimeout(() => {
                    window.location = payemntUrl
                }, 4000)
            }
        }
        catch (err) {
            console.error(err)
            if (err.response.status = 401) {
                dispatch(getNotification({
                    message: "Session expired! Please log in again",
                    type: "default"
                }))
                setTimeout(() => {
                    UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/pricing` })
                }, 3000)
            }
        }
        setLoader(false)
    }


    if (loader) {
        return (
            <div style={{ marginTop: "30vh" }} className='md:col-span-2 md:mt-0' >
                <Loader type="bubble-spin" bgColor={"#000000"} title={"Loading..."} color={'#000000'} size={100} />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div >
        )
    }
    else if (warning) {
        return (
            <div className="containe" style={{ marginTop: "30vh" }}>
                <p className='text-center fw-bolder'>Please do not refresh, close or navigate away from this page until the transaction is complete to ensure that your payment is processed successfully.
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ProgressBar
                            height="150"
                            width="5000"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{}}
                            wrapperclassName="progress-bar-wrapper"
                            borderColor='#F4442E'
                            barColor='#51E5FF'
                        />
                    </div>
                </p>
            </div>
        )
    }
    else if (subscriptionData.length) {
        return (
            <div className='bg-[#F9F9F9] '>
                <br />
                <br />
                <div className="row rounded-3xl h-full bg-white flex-row justify-between p-3">
                    <div className="col-sm">
                        <h2 className="text-gray-900 text-lg title-font font-medium flex">Pricing
                            {/* <img className="mx-3" src={wave} alt="wave" /> */}
                        </h2>
                        <p className="leading-relaxed text-base">Available at affordable monthly and annual prices.
                        </p>
                    </div>
                    <div className="col-sm flex-shrink-0 flex inline-flex items-center justify-content-end space-x-4 mr-5">
                        <VideoPopup videoSrc={vdo} />
                    </div>
                    {popup && <Popup onConfirm={() => {
                        // Clear local storage
                        localStorage.clear();
                        // Reload the page
                        setPopup(false)
                        window.location.reload();
                    }} onCancel={() => setPopup(false)} text={"This will erase all inputs and current sessions for all Scripters. Are you sure you want to proceed?"} proceed={true} />}
                </div>
                <div className='container-fluid'>
                    <div className="row mt-2">
                        {
                            subscriptionData && subscriptionData.map((ele, ind) => {
                                return (
                                    // <div key={ind} className={currencyCode === ele.currency_code && ele.validity_in_days === (monthly ? 1 : 365) || ele.subscription_plan_desc === "Starter-Dummy" ? "col-sm" : "d-none"}>
                                    <div key={ind} className="col-sm mt-4">
                                        <div key={ind} className="col-sm mt-4">
                                            <div className="blog-card alt">
                                                <div className="meta">
                                                    <div className="photo" style={ind === 0 ? { backgroundImage: `url(${subscription1})` } : { backgroundImage: `url(${subscription2})` }}></div>
                                                </div>
                                                <div className="description">
                                                    <h1 style={{ fontSize: "1.3rem" }}>INR {ele.price}/month (includes 18% GST)</h1>
                                                    <div className='px-3 my-0'>
                                                        {ind === 0 && <p className='my-1 mt-3'>Monthly renewal, cancel anytime.</p>}
                                                        {ind === 1 && <p> Annual renewal, cancel anytime.</p>}
                                                        <p className='my-1'>Bail Application.</p>
                                                        <p className='my-1'>Anticipatory Bail Application.</p>
                                                        <p className='my-1'>Unlimited AI generation.</p>
                                                        <p className='my-1'>Past generated Drafts Library.</p>
                                                        <p className='my-1'>Fully formatted Microsoft Word downloads.</p>
                                                    </div>
                                                    <p className="read-more">
                                                        <button
                                                            onClick={() => {
                                                                // if (UserService.isLoggedIn() && !state.pricingButtonValue)
                                                                //     navigate('/app/my-subscriptions')
                                                                // else
                                                                paymentPage(ele.currency_code, ele.subscription_id)
                                                            }}
                                                            className="btn btn-dark w-full mt-2">{UserService.isLoggedIn() && !state.pricingButtonValue ? "Manage" : `Buy ${ind === 0 ? "Monthly" : "Annual"} Subscription`}
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div >
                </div >
                <br /><br />
            </div >
        )
    }
}

export default Pricing