import React, { useState } from "react";

const VideoPopup = ({ videoSrc }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <div>
      {/* Button to trigger popup */}
      <button
        onClick={openPopup}
        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 px-5"
      >
        Watch Video
      </button>

      {/* Popup structure */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" style={{zIndex:"9999999"}}>
          {/* Video container */}
          <div className="relative bg-gray-800 rounded-lg shadow-lg w-[900px] h-[300px] sm:h-[500px] border border-gray-300">
            {/* Top Bar */}
            <div className="absolute top-0 left-0 w-full h-10 bg-gray-900 rounded-t-lg flex items-center px-4">
              {/* Close button */}
              <button
                onClick={closePopup}
                className="w-3 h-3 bg-red-500 rounded-full mr-2 cursor-pointer"
              ></button>
              <div className="w-3 h-3 bg-yellow-400 rounded-full mr-2"></div>
              <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            </div>

            {/* Video Section */}
            <div className="absolute top-10 left-0 w-full h-[calc(100%-40px)] overflow-hidden rounded-b-lg">
              <video
                src={videoSrc}
                controls
                autoPlay
                loop
                muted
                className="w-full h-full object-cover"
              >
                Your browser does not support the video tag.
              </video>
            </div>

            {/* Back Button */}
            <button
              onClick={closePopup}
              className="absolute top-1 right-4 text-white px-3 py-1 rounded"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPopup;
