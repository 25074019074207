export const noteforusers = "Section 1 is compulsory. Complete other sections for a more complete and accurate draft. Feel free to re-generate results, and download...";


export const formdata = [
    {
        name: "Section 1 - Details of Offences (compulsory)",
        group: [
            // {
            //   name: "Application filed under (not inserting any input will default to placeholder text):",
            //   placeholder: "Section 438 of the Code of Criminal Procedure, 1873",
            //   id: "crim_section_number",
            //   type: "text",
            //   element: "input-text",
            //   required: false,
            //   optional: true,
            // },
            {
                name: "Acts/Section Numbers under which Offences Committed:",
                placeholder: "S. 318 and S. 45 of the Bharatiya Nyaya Sanhita",
                id: "crim_offence_numbers",
                type: "text",
                element: "input-text",
                required: true,
                optional: false,
            },
            {
                name: "Whether all alleged offences entail imprisonment of less than 7 years under IPC?(defaults to Yes)",
                group: [
                    {
                        name: "Yes",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "sevenyearsimprisonment",
                        id: "seven_years_imprisonmentYes",
                    },
                    {
                        name: "No",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "sevenyearsimprisonment",
                        id: "seven_years_imprisonmentNo",
                    },
                ],
                // placeholder: " Yes/No",
                id: "seven_years_imprisonment",
                required: false,
                optional: true,
            },
            {
                name: "Brief Description of Offences or Allegations made against Applicant :  ",
                placeholder:
                    "Applicant made false representations to HDFC Bank for obtaining corporate loan facility and misused the funds for personal gain.",
                id: "allegation_facts",
                type: "text",
                element: "input-text",
                required: true,
                optional: false,
                wordLimit: true,
            },
            {
                name: "Facts set out in the Complaint/FIR : ",
                placeholder:
                    "Loan facility was approved in March 2020 for a highway project to be executed by X. The guarantee provided for the loan by Applicant was fraudulent. Funds were diverted to Applicant’s other companies, and then used for purchase of a mansion in Spain.",
                id: "complaint_facts",
                type: "text",
                element: "input-text",
                required: true,
                optional: false,
                wordLimit: true,
            },
        ],
    },
    {
        name: "Section 2: Details of Complaint (optional)",
        group: [
            {
                name: "Name of Complainant:",
                placeholder: "Devendra Sharma",
                id: "complainant_name",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Gender of Complainant:",
                type: "input-select",
                id: "gender_complainant",
                placeholder: "Male/Female",
                group: [
                    {
                        name: "Male",
                        element: "input-select",
                        nameAtt: "Male/Female",
                        id: "MaleGenderComplainant",
                        type: "input-select",
                    },
                    {
                        name: "Female",
                        element: "input-select",
                        type: "input-select",
                        nameAtt: "Male/Female",
                        id: "FemaleGenderComplainant",
                    },
                ],
            },
            {
                name: "Court Case Number: ",
                placeholder: "Crl. Misc. Petition No. 456 / 2023",
                id: "petitioner_number",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Date of Complaint:",
                placeholder: "23/10/2023",
                id: "complaint_date",
                type: "date",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "FIR Number:",
                placeholder: "insert FIR No.",
                id: "criminal_case_number",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Police Station Name:",
                placeholder: "JP Nagar P.S",
                id: "PS_Name",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Name of the Respondent:",
                placeholder: "State of Karnataka",
                id: "respondent_name",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
        ],
    },
    {
        name: "Section 3: Facts pertaining to Applicant/Client (optional)",
        group: [
            {
                name: "Name of Applicant:",
                placeholder: "Hari Sharma",
                id: "petitioner_name",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Date of Application:",
                placeholder: "Date Selection",
                id: "date_of_application",
                type: "date",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Applicant’s Place of Residence:",
                placeholder: "Mysore",
                id: "petitioner_residence_city",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Gender of Applicant:",
                type: "input-select",
                id: "gender_petitioner",
                placeholder: "Male/Female",
                group: [
                    {
                        name: "Male",
                        element: "input-select",
                        type: "input-select",
                        nameAtt: "Male/Female",
                        id: "MaleGenderApplicant",
                    },
                    {
                        name: "Female",
                        element: "input-select",
                        type: "input-select",
                        nameAtt: "Male/Female",
                        id: "FemaleGenderApplicant",
                    },
                ],
            },
            {
                name: "Whether Past Criminal Antecedents:",
                group: [
                    {
                        name: "Yes",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "CriminalAntecedents",
                        id: "criminal_antecedentsYes",
                    },
                    {
                        name: "No",
                        element: "input-radio",
                        type: "radio",
                        nameAtt: "CriminalAntecedents",
                        id: "criminal_antecedentsNo",
                    },
                ],
                id: "criminal_antecedents",
            },
            {
                name: "Brief and simple description of Applicant’s profession, residence, family background and other factors to establish good character and standing (not inserting any input will default to the placeholder text) :",
                placeholder:
                    " The Applicant is a businessman and entrepreneur. He is founder and CEO of X. The Applicant’s company executes projects for clients such as []. ",
                id: "petitioner_profession_reputation",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
                wordLimit: true,
            },
            {
                name: "Any difficulties currently being suffered by the Applicant or extenuating circumstances (not inserting any input will default to the placeholder text): ",
                placeholder:
                    "The Applicant is suffering from an ailment that requires treatment. He is the sole breadwinner in the family.",
                id: "petitioner_circumstances",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
                wordLimit: true,
            },
        ],
    },
    {
        name: "Section 4: Details of Court and judge: (optional)",
        group: [
            {
                name: "Name of the Court:",
                placeholder: "THE COURT OF LEARNED CITY CIVIL AND SESSIONS JUDGE",
                id: "court_name",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Place of Court:",
                placeholder: "BANGALORE",
                id: "court_place",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
        ],
    },
    {
        name: "Section 5: Details of Advocate (optional)",
        group: [
            {
                name: "Name of the Advocate:",
                placeholder: "Your/Advocate’s Name",
                id: "advocate_name",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
            {
                name: "Place:",
                placeholder: "Your/Advocate’s Place",
                id: "advocate_residence_city",
                type: "text",
                element: "input-text",
                required: false,
                optional: true,
            },
        ],
    },
];

export const fieldNames = [
    "complainant_name",
    "petitioner_number",
    "complaint_date",
    "criminal_case_number",
    "PS_Name",
    "petitioner_name",
    "date_of_application",
    "petitioner_residence_city",
    "gender_complainant",
    "gender_petitioner",
    "petitioner_profession_reputation",
    "petitioner_circumstances",
    "crim_offence_numbers",
    // "seven_years_imprisonment",
    "allegation_facts",
    "complaint_facts",
    "court_name",
    "court_place",
    "advocate_name",
    "advocate_residence_city",
    "respondent_name",
];

